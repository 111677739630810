import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Skeleton,
  Typography
} from '@mui/material'
import {
  BookmarkAccountButton,
  Chatbot
} from 'components'
import {
  ArrowLeft,
  Clipboard
} from 'icons'
import {
  usePreloadImage
} from 'hooks'
import {
  ACCOUNT_PAGE_TEXT_DATA,
  copyToClipboard,
  customAlert,
  formatDate,
  joinWithAnd
} from 'utils-em'

import { AccountPageContext } from './components/AccountPageContext'
import LandingPage from './components/LandingPage'
import CoachingSection from './components/CoachingSection'
import SellingTips from './components/SellingTips'
import TechStack from './components/TechStack'
import WhiskerSection from './components/WhiskerSection'
import LastUpdatedTag from './components/LastUpdatedTag'
import SideNavigation from './components/SideNavigation'
import ArtificialIntelligenceSection from './components/ArtificialIntelligenceSection'

const AccountPage = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const location = useSelector(({ router }) => router.location)

  const {
    activeSectionKey,
    accountPage,
    scrollableElement,
    loaded,
    accountSaved,
    savedListLoaded,
    checkIfAccountSaved,
  } = React.useContext(AccountPageContext)
  const organization = accountPage?.organization

  const [logoUrl, setLogoUrl] = React.useState(null)
  const preloaded = usePreloadImage(logoUrl)

  const loadLogoUrl = async () => {
    if (accountPage?.logo) {
      setLogoUrl(accountPage.logo)
      return
    }

    if (!accountPage?.logoGcsObjectName) return

    const url = `${__API_HOST__}/v1/accountPages/logoUrls?accountPageIds=[${accountPage.id}]`
    const res = await fetch(url, { credentials: 'include', method: 'GET' })
    const json = await res.json()
    setLogoUrl(json[accountPage.id])
  }
  React.useEffect(() => { loadLogoUrl() }, [loaded])

  if (!loaded) { return <Skeleton /> }

  const getActiveSection = () => {
    switch (activeSectionKey) {
      case 'sellingTips':
        return <SellingTips />
      case 'artificialIntelligence':
        return <ArtificialIntelligenceSection />
      case 'propensityToBuy':
      case 'techPreferences':
      case 'buyingProcess':
        return <WhiskerSection />
      case 'techStack':
        return <TechStack />
      case 'coaching':
        return <CoachingSection />
      case 'chatbot':
        return <Chatbot filters={{ organizationId: accountPage.organization.id }} />
      default:
        return <LandingPage />
    }
  }
  const activeSection = getActiveSection()

  const renderLogo = () => {
    if (organization && (!accountPage?.logoGcsObjectName && !accountPage?.logo)) return <Typography variant="h1">{organization?.name}</Typography>
    if (!loaded || !preloaded) return <Skeleton variant="rounded" height="80px" width="200px" />
    return <img alt="company-logo" src={logoUrl} height="80px" />
  }

  const renderHeaderBelowTitle = () => {
    if (activeSectionKey === 'coaching') {
      return (
        <Typography variant="h3" color="neutral.black" sx={{ ml: 3, mr: 3, mt: 1 }}>
          Here are the Emissary advisors from&nbsp;
          <strong>{accountPage.organization.name}</strong>
          . Schedule time with our experts and gain valuable deal guidance and selling recommendations.
        </Typography>
      )
    }
    if (activeSectionKey === 'landingPage') {
      return (
        <Typography variant="h3" color="neutral.black" sx={{ px: 3, py: 1.5 }}>
          {
            accountPage.contributingAdvisorSummary?.functionsOfRole?.length > 0
              ? `The advisors who provided the data in this report came from the
              ${joinWithAnd(accountPage.contributingAdvisorSummary?.functionsOfRole)} functional area${accountPage.contributingAdvisorSummary?.functionsOfRole.length === 1 ? '' : 's'}
              at `
              : 'This report contains insights gathered from advisors at '
          }
          <strong>{accountPage.organization.name}</strong>
          . Last updated on
          <strong>{` ${formatDate(accountPage.mostRecentUpdateDate, { includeYear: true })}`}</strong>
          .
        </Typography>
      )
    }
    return (
      <>
        <Typography variant="h2" color="neutral.black" sx={{ px: 3, py: 1.5 }}>
          {ACCOUNT_PAGE_TEXT_DATA[activeSectionKey].title}
        </Typography>
        {activeSectionKey !== 'chatbot' ? <LastUpdatedTag /> : null}
      </>
    )
  }

  return (
    <Box
      id="account-page"
      sx={{ display: 'flex', flexDirection: 'column' }}
      ref={scrollableElement}
    >
      {/* Header */}
      <Box sx={{ display: 'flex', px: 2, py: 1.5 }}>
        <Button
          variant="text"
          startIcon={<ArrowLeft />}
          onClick={() => history.goBack()}
        >
          {location?.state?.from === '' ? 'Back to account overview' : 'Back'}
        </Button>
        <Box sx={{ ml: 'auto', display: 'flex' }}>
          <BookmarkAccountButton orgId={organization.id} accountSaved={accountSaved} savedListLoaded={savedListLoaded} checkIfAccountSaved={checkIfAccountSaved} />
          <Button
            variant="text"
            startIcon={<Clipboard />}
            onClick={() => copyToClipboard(
              activeSectionKey !== 'landingPage' ? `${__APP_HOST__}${pathname}#${activeSectionKey}` : `${__APP_HOST__}${pathname}`,
              () => customAlert('Link copied to clipboard.')
            )}
          >
            Copy link to share
          </Button>
        </Box>
      </Box>
      <Box sx={{ px: 3, py: 1.5 }}>
        {renderLogo()}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {renderHeaderBelowTitle()}
      </Box>

      <Divider sx={{ mt: 5, mb: 5, borderColor: 'brand.paleSlate', borderBottomWidth: '4px' }} />

      {/* Main content */}
      {activeSectionKey === 'landingPage' ? (
        <>
          {activeSection}
        </>
      ) : (
        <Box sx={{ flex: 1, display: 'flex', gap: 3 }}>
          <Box sx={{
            flex: 1,
            p: 1,
            position: 'relative',
            borderRightWidth: '1px',
            borderRightColor: 'neutral.lightGrey',
            borderRightStyle: 'solid'
          }}
          >
            <SideNavigation />
          </Box>
          <Box sx={{ flex: 2 }}>
            {activeSection}
          </Box>
        </Box>
      )}

      {/* Footer */}
      <Box sx={{ mb: ({ footerHeight }) => (activeSectionKey === 'landingPage' ? 0 : `${footerHeight}`) }}>
        <Divider sx={{ my: 5, borderBottomWidth: '1px' }} />
        <Typography variant="caption" color="neutral.black" sx={{ mt: 3, display: 'block' }}>
          {`This report is licensed to you under the Emissary Inc. customer agreement for your organization's
              internal use only - you may not redistribute, sell, license or otherwise transfer it without
              Emissary's prior written approval. Although the information in this report is believed to have been
              obtained from reliable sources, much of this information is subjective in nature and in all cases,
              Emissary makes no representation as to its accuracy or completeness.`}
        </Typography>
      </Box>
    </Box>
  )
}

export default AccountPage
