import React from 'react'
import { useForm } from 'react-hook-form'
import { useJsonAPIUpsert } from 'hooks'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Typography,
} from '@mui/material'

import {
  closeDialog,
} from 'utils-em'

import { useSelector } from 'react-redux'

import {
  ConfirmDialog,
  FormTextField,
  FormToggle,
  FormSelect
} from 'components'

const gptModels = [
  'gpt-4o',
  'gpt-4-turbo',
  'gpt-4',
  'gpt-3.5-turbo',
  'gpt-3.5-turbo-16k',
  'gpt-3.5-turbo-0125',
]

const gptModelLimits = {
  'gpt-4o': 128_000,
  'gpt-4': 8191,
  'gpt-4-turbo': 4096,
  'gpt-3.5-turbo': 4096,
  'gpt-3.5-turbo-16k': 16384,
  'gpt-3.5-turbo-0125': 4096,
}

const CreateEditPromptDialog = ({ prompt }) => {
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const prompts = useSelector(({ data }) => data.prompts)
  const activePrompt = prompts ? prompts[Object.keys(prompts).find((key) => prompts[key].attributes.useForChatbot)] : null

  const form = useForm({
    defaultValues: {
      name: prompt?.name || null,
      model: prompt?.model || 'gpt-4-turbo',
      temperature: prompt?.temperature.toString() || '0.1',
      maxTokens: prompt?.maxTokens.toString() || '1500',
      topP: prompt?.topP.toString() || '1',
      frequencyPenalty: prompt?.frequencyPenalty.toString() || '0',
      presencePenalty: prompt?.presencePenalty.toString() || '0',
      promptText: prompt?.promptText || null,
      useForChatbot: prompt?.useForChatbot || false,
    }
  })
  const [validate, setValidate] = React.useState(false)

  const onSubmit = async (data) => {
    await upsert({
      type: 'prompts',
      ...data,
      id: prompt?.id,
    })
    if (form.getValues().useForChatbot && activePrompt && activePrompt.id !== prompt?.id) {
      setValidate(true)
      return
    }
    closeDialog()
  }

  const validateView = {
    description: (
      <Box>
        <Typography>{`There can only be one active prompt in the chatbot. Please deactivate the following prompt: ${activePrompt?.attributes.name}.`}</Typography>
      </Box>
    ),
    actions: [
      {
        name: 'Go back',
        preventClose: true,
        action: () => {
          setValidate(false)
        }
      }
    ],
  }
  const formView = {
    description: (
      <Box sx={{ padding: 2 }} component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField
              name="name"
              label="Name"
              form={form}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="model"
              label="Model"
              items={gptModels.map((model) => ({ value: model, label: model }))}
              form={form}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormTextField
              name="temperature"
              label="Temperature"
              form={form}
              type="number"
              required
              lowestValidNumber={0}
              highestValidNumber={2}
              helperText="Number between 0-2"
              tooltip="Controls randomness: Lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive"
            />
          </Grid>
          <Grid item xs={4}>
            <FormTextField
              name="maxTokens"
              label="Max tokens"
              form={form}
              type="number"
              required
              helperText={`Number between 1-${gptModelLimits[form.watch('model') || 'gpt-4-turbo']}`}
              lowestValidNumber={1}
              highestValidNumber={gptModelLimits[form.watch('model') || 'gpt-4-turbo']}
              tooltip="The maximum number of tokens to generate shared between the prompt and completion. The exact limit varies by model. One token is roughly four characters in English text."
            />
          </Grid>
          <Grid item xs={4}>
            <FormTextField
              name="topP"
              label="Top P"
              form={form}
              type="number"
              required
              helperText="Number between 0-1"
              lowestValidNumber={0}
              highestValidNumber={1}
              tooltip="Controls diversity via nucleus sampling: 0.5 means half of all likelihood-weighted options are considered."
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              name="frequencyPenalty"
              label="Frequency penalty"
              form={form}
              type="number"
              required
              helperText="Number between 0-2"
              lowestValidNumber={0}
              highestValidNumber={2}
              tooltip="How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat the same line verbatim."
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              name="presencePenalty"
              label="Presence Penalty"
              form={form}
              type="number"
              required
              helperText="Number between 0-2"
              lowestValidNumber={0}
              highestValidNumber={2}
              tooltip="How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics."
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="promptText"
              label="Prompt text"
              form={form}
              required
              multiline
              rows={4}
              helperText="Use Jinja format to include things like {{ organization_name }} or {{ survey_data }}"
            />
          </Grid>
          <Grid item xs={12}>
            <FormToggle
              name="useForChatbot"
              label="Use for chatbot"
              form={form}
            />
          </Grid>
        </Grid>

      </Box>
    ),
    actions: [
      {
        name: 'Save',
        preventClose: true,
        action: form.handleSubmit(onSubmit)
      },
    ]
  }

  return (
    <ConfirmDialog
      open
      onClose={closeDialog}
      maxWidth="md"
      title="Build a new prompt"
      {...(validate ? validateView : formView)}
      cancelName={(validate ? 'Close' : 'Cancel')}
    />
  )
}

CreateEditPromptDialog.defaultProps = {
  prompt: null,
}

CreateEditPromptDialog.propTypes = {
  prompt: PropTypes.object,
}

export default CreateEditPromptDialog
