import React from 'react'
import { Box } from '@mui/material'
import { Tabs } from 'components'
import ManagePrompts from './components/ManagePrompts'

const PromptManagement = () => (
  <Box sx={(theme) => ({ ...theme.pageContentSx, width: '100%' })}>
    <Box sx={{ py: 4, height: '100%' }}>
      <Box typography="h1" variant="h1" sx={{ p: 2 }}>
        Prompts
      </Box>
      <Tabs
        tabs={[
          { name: 'Manage', component: <ManagePrompts />, hash: '#prompts' },
        ]}
        panelProps={{ minHeight: '100%' }}
        sx={{ height: '100%' }}
      />
    </Box>
  </Box>
)

export default PromptManagement
