import moment from 'moment'
import { get, orderBy } from 'lodash'

function internalDateFormatter (date, formatStr, showTimezoneSuffix, timezone) {
  if (!date) return ''
  const tzDate = moment.tz(date, timezone || moment.tz.guess())
  const formatterString = formatStr + (showTimezoneSuffix ? ' z' : '')
  return tzDate.format(formatterString)
}

export function formatDate (date, options = {}) {
  // E.g. "Jan 25, 2022"
  const { showTimezoneSuffix, timezone, includeYear, longMonth, includeDayOfWeek } = {
    showTimezoneSuffix: false,
    timezone: null,
    includeYear: new Date().getFullYear() !== new Date(date).getFullYear(),
    longMonth: false,
    includeDayOfWeek: false,
    ...options
  }
  let formatStr = includeYear ? 'MMM D, YYYY' : 'MMM D'
  if (longMonth) formatStr = formatStr.replace('MMM', 'MMMM')
  if (includeDayOfWeek) formatStr = `dddd, ${formatStr}`

  return internalDateFormatter(date, formatStr, showTimezoneSuffix, timezone)
}

export function formatTime (date, options = {}) {
  // E.g. "12:15 am", "5:30 pm"
  const { showTimezoneSuffix, timezone } = { showTimezoneSuffix: false, timezone: null, ...options }
  return internalDateFormatter(date, 'h:mm a', showTimezoneSuffix, timezone)
}

export function formatDateTime (date, options = {}) {
  // E.g. "Apr 1, 2019 7:00 am"
  const { showTimezoneSuffix, timezone } = { showTimezoneSuffix: false, timezone: null, ...options }
  return internalDateFormatter(date, 'MMM D, YYYY h:mm a', showTimezoneSuffix, timezone)
}

export function formatDateRange (startDate, endDate) {
  // E.g. "Jan 22, 2020 - Mar 5, 2021"
  return `${formatDate(startDate)} - ${formatDate(endDate)}`
}

export function formatTimeRange (startDate, endDate, options = {}) {
  // E.g. "11:00 am - 12:00 pm"
  const { timezone } = { showTimezoneSuffix: false, timezone: null, ...options }
  return `${formatTime(startDate, { timezone })} - ${formatTime(endDate, options)}`
}

export function formatDateTimeRange (startDate, endDate, options = {}) {
  // E.g. "Dec 10, 2021 4:00 pm - 5:00 pm"
  const { timezone } = { showTimezoneSuffix: false, timezone: null, ...options }
  return `${formatDate(startDate, { timezone })} ${formatTimeRange(startDate, endDate, options)}`
}

export function formatDayOfWeekForHumans (date) {
  // Format: Monday, Tuesday, ...
  const userTimezone = moment.tz.guess()
  return moment.tz(date, userTimezone).format('dddd')
}

export function formatMonthYearForHumans (date, useShortMonth = false) {
  // Format: Jan 2022 [OR] January 2022
  if (!date) { return '' }
  const userTimezone = moment.tz.guess()
  const dateFormat = useShortMonth ? 'MMM Y' : 'MMMM Y'
  return moment.tz(date, userTimezone).format(dateFormat)
}

// to maintain consistency, we anchor all day/months to the 15th of a month at midnight
export function formatAsMiddleOfMonth (momentDate) {
  return momentDate.utc().startOf('day').date(15)
}

export function timeBetweenTwoDates (earlierDate, laterDate) {
  if (!earlierDate || !laterDate) { return '' }

  const earlierMoment = moment(earlierDate)
  const laterMoment = moment(laterDate)

  let years = laterMoment.diff(earlierMoment, 'years')
  // Math.round() on the Moment `.diff(_, _, precision=true)` for months may seem
  // redundant here, but is intentional. Moment logic truncates (i.e. rounds down)
  // when precision is off, but we want rounding as normal.
  let months = Math.round(laterMoment.diff(earlierMoment, 'months', true) % 12)
  if (months === 12) {
    // rounding up to 12 months should be counted as another year,
    // to avoid display issues, like "1 year and 12 months"
    years += 1
    months = 0
  }
  return `${years} years and
    ${months} months`
}

export function formatTimeslotForHumans (timeslot, includeDayandDate = true) {
  const startTime = formatTime(timeslot.startTime)
  const endTime = formatTime(timeslot.endTime, { showTimezoneSuffix: true })

  let formatted = `${startTime} - ${endTime}`
  if (includeDayandDate) {
    const dayOfWeek = formatDayOfWeekForHumans(timeslot.startTime)
    const date = formatDate(timeslot.startTime)
    formatted = `${dayOfWeek}, ${date} ${formatted}`
  }
  return formatted
}

export function formatTimeAgoForHumans (date) {
  return moment(date).fromNow()
}

export function differenceBetweenDates (earlierDate, laterDate, unit) {
  return moment(laterDate).diff(moment(earlierDate), unit)
}

export function numberOfDaysBetweenDates (earlierDate, laterDate, options = {}) {
  const { truncateTime } = { truncateTime: false, ...options }
  const startDate = truncateTime ? new Date(earlierDate).setHours(0, 0, 0, 0) : earlierDate
  const endDate = truncateTime ? new Date(laterDate).setHours(0, 0, 0, 0) : laterDate
  return differenceBetweenDates(startDate, endDate, 'days')
}

export function formatTimeRemainingForHumans (laterDate) {
  const days = numberOfDaysBetweenDates(new Date(), laterDate)

  if (days < 45) return `${days} day${days > 1 ? 's' : ''} remaining`

  const months = Math.floor(days / 30)
  return `${months} month${months > 1 ? 's' : ''} remaining`
}

export function daysRemaining (startDate, numberOfDays) {
  return moment(startDate).clone().add(numberOfDays, 'days').diff(moment(), 'days')
}

export function displayDaysRemaining (startDate, lengthInDays) {
  if (lengthInDays === null || lengthInDays === undefined) {
    return 'N/A'
  }

  const numDays = moment(startDate).clone().add(lengthInDays, 'days').diff(moment(), 'days')

  if (numDays === 1) {
    return 'until tomorrow'
  } if (numDays === 0) {
    return 'until end of day'
  } if (numDays < 0) {
    return `${Math.abs(numDays)} days ago`
  }

  return `for ${numDays} more days`
}

export function calculateDecimalYears (earlierDate, laterDate) {
  if (!earlierDate || !laterDate) { return '' }

  const earlierMoment = moment(earlierDate)
  const laterMoment = moment(laterDate)
  const tenureYears = laterMoment.diff(earlierMoment, 'years', true)
  return tenureYears.toFixed(1)
}

export function calculateCompanyTenure (company, decimalYears = null) {
  // don't depend on the order of roles from the server...sort them
  const mostRecentRole = orderBy(company.roles.filter((role) => !role.deleted), 'dateEnded', 'desc')[0]
  const leastRecentRole = orderBy(company.roles.filter((role) => !role.deleted), 'dateStarted', 'asc')[0]

  if (!mostRecentRole) { return '' }

  const earlierDate = get(company, 'dateStarted') || get(leastRecentRole, 'dateStarted')
  if (!earlierDate) { return '' }

  let laterDate
  if (mostRecentRole.dateEnded) {
    laterDate = mostRecentRole.dateEnded
  } else {
    laterDate = moment()
  }
  if (decimalYears) {
    return calculateDecimalYears(earlierDate, laterDate)
  }
  return timeBetweenTwoDates(earlierDate, laterDate)
}

export function createFutureDate (date, addYears, addMonths, addDays) {
  const futureDate = new Date(date)
  futureDate.setFullYear(date.getFullYear() + addYears)
  futureDate.setMonth(date.getMonth() + addMonths)
  futureDate.setDate(date.getDate() + addDays)
  return futureDate
}
