import React from 'react'

import { Box, Typography } from '@mui/material'
import { Calendar } from 'icons'
import {
  formatDate
} from 'utils-em'
import { AccountPageContext } from '../AccountPageContext'

const LastUpdatedTag = () => {
  const { accountPage } = React.useContext(AccountPageContext)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
      <Calendar sx={{ fontSize: 16 }} />
      <Typography variant="caption">{`Last updated ${formatDate(accountPage.mostRecentUpdateDate, { includeYear: true })}`}</Typography>
    </Box>
  )
}

export default LastUpdatedTag
