import thunk from 'redux-thunk'
import { combineReducers, applyMiddleware, createStore, compose } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'

import resourceReducer from './_resources/reducer'
import appStateReducer from './AppState/reducer'
import sessionReducer from './SessionAPI/reducer'
import enumReducer, { ENUM_KEY_TO_ENUM_TYPE } from './EnumAPI/reducer'
import jsonApiReducer from './JsonAPI/reducer'
import dialogReducer from './Dialogs/reducer'
import alertReducer from './Alerts/reducer'
import gtagReducer from './Gtag/reducer'
import { SentryEnhancer } from '../sentry_ext'

import AppState from './AppState'
import Dialogs from './Dialogs'
import SessionAPI from './SessionAPI'
import { JsonAPI, JsonAPIActions } from './JsonAPI'
import EnumAPI from './EnumAPI'
import Alerts from './Alerts'
import Gtag from './Gtag'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
})

let composeEnhancers = compose
if (__FOR_DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  const options = { latency: 0, maxAge: 50 }
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(options)
}

const store = createStore(
  combineReducers({
    router: routerReducer,
    appState: appStateReducer,
    resource: resourceReducer,
    session: sessionReducer,
    enums: enumReducer,
    dialogs: dialogReducer,
    alerts: alertReducer,
    data: jsonApiReducer,
    gtag: gtagReducer
  }),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware,
      thunk
    ),
    SentryEnhancer
  )
)

const history = createReduxHistory(store)

export {
  store,
  history,
  AppState,
  Dialogs,
  Alerts,
  JsonAPI,
  JsonAPIActions,
  SessionAPI,
  EnumAPI,
  Gtag,
  ENUM_KEY_TO_ENUM_TYPE
}
