import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Link
} from '@mui/material'
import {
  useEngagement
} from 'hooks'
import {
  constants,
  navigateTo
} from 'utils-em'

const ProposalExpired = ({ engagementId }) => {
  const { isAdvisor, advisor } = useEngagement(engagementId)

  if (isAdvisor) {
    return <>TODO</>
  }

  return (
    <>
      <Typography variant="h3">
        {'We\'re sorry!'}
      </Typography>
      <Typography variant="body1">
        {`${advisor?.firstName} did not respond to your call proposal within the ${constants.ENGAGEMENT_PROPOSAL_EXPIRATION_TIME_IN_DAYS} day window. `}
        <Link onClick={() => navigateTo('/c/search')}>Find another advisor</Link>
        &nbsp;from our network.
      </Typography>
    </>

  )
}

ProposalExpired.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default ProposalExpired
