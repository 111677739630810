import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography
} from '@mui/material'
import { Heart } from 'icons'

const AccountSavedCard = ({ accountSaved, overrideSX = {} }) => (
  <Box sx={{ bgcolor: '#ecf1f3', borderRadius: 2, p: 3, my: 3, mr: 1.5, ml: 2.5, ...overrideSX }}>
    <Heart />
    <Typography variant="caption" sx={{ display: 'block' }}>
      {
        accountSaved ? (
          <>
            <Typography variant="captionBold" sx={{ display: 'inline' }}>This account is saved to your list, so you will </Typography>
            get notifications when research is available or when new advisors come onboard.
            <Typography variant="captionBold" sx={{ display: 'inline' }}> Save an advisor </Typography>
            to your list if you’re not ready to reach out yet.
          </>
        ) : (
          <>
            <Typography variant="captionBold" sx={{ display: 'inline' }}>Save this account </Typography>
            to get notifications when research is available or when new advisors come onboard.
            <Typography variant="captionBold" sx={{ display: 'inline' }}> Save an advisor </Typography>
            to your list if you’re not ready to reach out yet.
          </>
        )
      }
    </Typography>
  </Box>
)
AccountSavedCard.propTypes = {
  accountSaved: PropTypes.string.isRequired,
  overrideSX: PropTypes.object
}

export default AccountSavedCard
