import PropTypes from 'prop-types'
import React from 'react'
import { Box } from '@mui/material'
import { constants } from 'utils-em'

import APILoaderContainer from '../../../../../containers/APILoaderContainer'
import OrganizationList from '../components/OrganizationList'

const OrganzationEditorLayout = ({ getAllOrgs }) => (
  <APILoaderContainer
    onMount={(done) => {
      getAllOrgs(constants.ADMIN_DEFAULT_ROWS_PER_PAGE, 1, 'name').then(() => { done() })
    }}
  >
    <Box sx={{ mt: 8 }}>
      <OrganizationList />
    </Box>
  </APILoaderContainer>
)

OrganzationEditorLayout.propTypes = {
  getAllOrgs: PropTypes.func.isRequired
}

export default OrganzationEditorLayout
