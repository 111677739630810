import React from 'react'
import {
  Box
} from '@mui/material'

import {
  SideNavigationMenu,
  SuggestAnAdvisorSection,
  AccountSavedCard
} from 'components'
import { useFeatureFlag } from 'hooks'
import {
  ACCOUNT_PAGE_SECTION_DATA,
  ACCOUNT_PAGE_DATA_TEMPLATE,
  accountPageSectionHasData,
  accountPageOrgHasChatbot,
  getArtificialIntelligenceSurveyData
} from 'utils-em'

import { AccountPageContext } from '../AccountPageContext'
import JumpToCoachingSection from '../JumpToCoachingSection'

const SideNavigation = () => {
  const {
    accountPage,
    activeSectionKey,
    setActiveSectionKey,
    scrollableElement,
    responsesLoaded,
    responses,
    accountSaved,
  } = React.useContext(AccountPageContext)

  const navigateToSection = (section) => {
    setActiveSectionKey(section.key)
    scrollableElement.current?.scrollTo(0, 0)
    scrollableElement.current?.parentNode?.scrollTo(0, 0)
    window.scrollTo(0, 0)
  }

  const chatbotBeta = useFeatureFlag('chatbot_beta')
  const directToPageActive = useFeatureFlag('direct_to_page_insights')
  const sectionsToRender = ACCOUNT_PAGE_SECTION_DATA
    .filter((section) => !['weJustHeard', 'chatbot'].includes(section.key))
    .filter((section) => {
      const accountPageDataTypes = Object.keys(ACCOUNT_PAGE_DATA_TEMPLATE)
      if (!accountPageDataTypes.includes(section.key)) {
        return true
      }
      if (section.key === 'artificialIntelligence') {
        return true
      }
      return accountPageSectionHasData(accountPage.data[section.key], section.key)
    })
    .map((section) => ({
      ...section,
      hidden: section.key === 'artificialIntelligence',
      hash: `#${section.key}`
    }))
  const [newSections, setNewSections] = React.useState(sectionsToRender)

  const showAIMenuItem = () => {
    if (!directToPageActive || newSections.some((section) => section.key === 'artificialIntelligence' && !section.hidden)) {
      return
    }
    const aiPageData = ACCOUNT_PAGE_SECTION_DATA.find((sec) => sec.key === 'artificialIntelligence')
    const containsData = getArtificialIntelligenceSurveyData(responsesLoaded, aiPageData, responses)
    if (containsData.length) {
      const repackagedMenuItems = newSections.map((section) => {
        if (section.key === 'artificialIntelligence') {
          return {
            ...section,
            hidden: false
          }
        }
        return section
      })
      setNewSections(repackagedMenuItems)
    }
  }

  const addChatbotMenuItem = async () => {
    const chatbotSection = ACCOUNT_PAGE_SECTION_DATA.find((section) => section.key === 'chatbot')
    if (!chatbotSection || !chatbotBeta || newSections.some((section) => section.key === 'chatbot')) return
    const hasDataForChatbot = await accountPageOrgHasChatbot(accountPage.organizationId)
    if (hasDataForChatbot) {
      const chatbotMenuItem = {
        ...chatbotSection,
        hash: `#${chatbotSection.key}`
      }
      setNewSections([chatbotMenuItem, ...sectionsToRender])
    }
  }

  const [extraSectionHeight, setExtraSectionHeight] = React.useState(0)
  const showExtraSection = !['techStack', 'coaching'].includes(activeSectionKey)

  React.useEffect(() => {
    showAIMenuItem()
    addChatbotMenuItem()
    const handleResize = () => {
      const extraSectionEl = document.getElementById('jump-to-coaching-section') || document.getElementById('suggest-advisor-section')
      if (!extraSectionEl) return
      const { height } = extraSectionEl.getBoundingClientRect()
      setExtraSectionHeight(height)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [responsesLoaded])

  return (
    <>
      <Box sx={{ position: 'sticky', top: '10px', display: 'flex', flexDirection: 'column', minHeight: `calc(308px + ${extraSectionHeight}px + 10px)` }}>
        <Box sx={{ height: showExtraSection || activeSectionKey === 'coaching' ? `calc(508px + ${extraSectionHeight}px + 10px)` : 'auto' }}>
          {/* Height calculation above adds artificial height to the menu so it stops
            early (in terms of its "sticky" position) to not overlap the bottom-pinned
            JumpToCoachingSection; add a border-bottom to help visually debug */}
          <SideNavigationMenu
            menuItems={newSections}
            defaultActiveHash={`#${activeSectionKey}`}
            onChange={navigateToSection}
          />
          <AccountSavedCard accountSaved={accountSaved} />
        </Box>
      </Box>
      {activeSectionKey === 'coaching' && <SuggestAnAdvisorSection organizationId={accountPage.organization.id} />}
      {showExtraSection ? <JumpToCoachingSection /> : null}
    </>
  )
}

export default SideNavigation
