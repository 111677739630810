import PropTypes from 'prop-types'
import React from 'react'
import {
  Avatar,
  Box,
  Card,
  Paper,
  Tooltip,
  Typography
} from '@mui/material'
import {
  QuestionMark
} from 'icons'

const MetricCard = ({ label, value, icon, color, tooltip }) => (
  <Card sx={{ cursor: 'pointer', minHeight: '100%', width: '100%', borderRadius: '8px', border: 0 }}>
    <Paper elevation={0} sx={{ p: 3, bgcolor: 'brand.lightNavy' }}>
      <Box sx={{ display: 'flex', columnGap: 2, alignItems: 'center' }}>
        <Avatar
          sx={{
            bgcolor: 'white',
            height: '32px',
            width: '32px'
          }}
        >
          {icon}
        </Avatar>
        <Typography id={`count-${label}`} variant="h2" sx={{ color }}>
          {value}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="caption" sx={{ color: 'neutral.darkGrey' }}>
          {label}
        </Typography>
        {tooltip ? (
          <Tooltip title={tooltip} placement="top">
            <span>
              <QuestionMark sx={{ color: 'neutral.darkGrey' }} />
            </span>
          </Tooltip>
        ) : null}
      </Box>
    </Paper>
  </Card>
)

MetricCard.defaultProps = {
  color: 'neutral.darkGrey',
  tooltip: ''
}

MetricCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  icon: PropTypes.node.isRequired,
  color: PropTypes.string,
  tooltip: PropTypes.string
}

export default MetricCard
