import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Divider
} from '@mui/material'
import { useEmissaryTheme } from 'hooks'

const EngagementLayout = ({ header, content, sidebar }) => {
  const {
    contentHeight,
    isMdOrSmaller,
    pagePaddingXPx,
    pagePaddingYPx,
    verticalSpacing,
    gutter
  } = useEmissaryTheme()
  return (
    <Box
      id="customer-engagement"
      sx={{
        minHeight: contentHeight,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {header}
      <Box
        id="engagement-panels"
        flex={1}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: isMdOrSmaller ? 'column' : 'row',
        }}
      >
        <Box
          id="engagement-content"
          sx={{
            flex: { sm: 1, lg: 7 },
            pr: { sm: 0, lg: gutter },
            py: verticalSpacing,
            height: '100%',
          }}
        >
          {content}
        </Box>
        <Divider
          flexItem
          orientation={isMdOrSmaller ? 'horizontal' : 'vertical'}
          sx={{
            bgcolor: 'neutral.lightGrey',
            ...(isMdOrSmaller
              ? { width: `calc(100% + 2 * ${pagePaddingXPx})`, mx: `-${pagePaddingXPx}` } // override layout-box padding
              : { minHeight: `calc(100% + ${pagePaddingYPx})`, mb: `-${pagePaddingYPx}` }), // override layout-box padding
          }}
        />
        <Box
          id="engagement-sidebar"
          sx={{
            flex: { sm: 0, lg: 3 },
            p: gutter,
            pl: { sm: 0, lg: gutter },
          }}
        >
          {sidebar}
        </Box>
      </Box>
    </Box>
  )
}

EngagementLayout.propTypes = {
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired
}

export default EngagementLayout
