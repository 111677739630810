import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Link,
  Typography
} from '@mui/material'

import {
  AdvisorCallCompletedText
} from 'components'
import {
  useEngagement
} from 'hooks'
import {
  formatDateTime,
  navigateTo
} from 'utils-em'

import ActivityItem from '../ActivityItem'

const CallCompleted = ({ activity, engagementId, user, ...props }) => {
  const {
    advisor,
    advisorFeedback
  } = useEngagement(engagementId)
  const isAdvisorUser = user.userType === 'advisor'

  return (
    <ActivityItem
      title="Call completed"
      subtitle={formatDateTime(activity.dateCreated)}
      {...props}
    >
      {isAdvisorUser ? (
        <Box sx={{ mt: 2 }}>
          <AdvisorCallCompletedText
            engagementId={engagementId}
            typography="body1"
            color="neutral.black"
          />
          <Box sx={{ mt: 2 }}>
            {!advisor.isPayable ? (
              <Link onClick={() => navigateTo('/a/profile/edit#payment-info')} sx={{ display: 'block', mb: 1 }}>
                <Typography variant="bodyBold">
                  Enter payment information
                </Typography>
              </Link>
            ) : null}
            {!advisorFeedback ? (
              <Link onClick={() => { /* TODO: sc-32527 */ }}>
                <Typography variant="bodyBold">
                  Submit feedback
                </Typography>
              </Link>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </ActivityItem>
  )
}

CallCompleted.propTypes = {
  activity: PropTypes.object.isRequired,
  engagementId: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
}

export default CallCompleted
