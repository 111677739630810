import React from 'react'
import { Box, Typography } from '@mui/material'

import AccountPageTab from './components/AccountPageTab'

const AccountPageManagement = () => (
  <Box sx={{ width: '100%', m: 2 }}>
    <Typography sx={{ fontSize: '20px', m: 1 }}>
      Account Page Management
    </Typography>
    <AccountPageTab />
  </Box>
)

export default AccountPageManagement
