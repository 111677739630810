import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField
} from '@mui/material'
import {
  useSelector
} from 'react-redux'
import {
  useJsonAPIUpsert
} from 'hooks'
import {
  buildJsonApiOne,
  closeDialog
} from 'utils-em'

const EditAnswerDialog = ({ questionResponseId }) => {
  const [editingText, setEditingText] = React.useState('')
  const questionResponse = useSelector(({ data }) => buildJsonApiOne(data, 'questionResponses', questionResponseId))

  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  React.useEffect(() => {
    setEditingText(questionResponse.editedAnswerText || questionResponse.textAnswer)
  }, [])

  const handleSave = async () => {
    await upsert({
      type: 'questionResponses',
      id: questionResponseId,
      editedAnswerText: editingText
    })
    closeDialog()
  }

  return (
    <Dialog
      scroll="paper"
      open
    >
      <DialogContent sx={{ mt: 2 }}>
        <TextField
          label="Answer text"
          value={editingText}
          onChange={(e) => setEditingText(e.target.value)}
          fullWidth
          rows={3}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={closeDialog}
          sx={{ ml: 2 }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => { handleSave() }}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditAnswerDialog.propTypes = {
  questionResponseId: PropTypes.string.isRequired
}

export default EditAnswerDialog
