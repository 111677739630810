import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Chip,
  Link,
  Stack,
  Typography
} from '@mui/material'

import {
  SanitizedHTML
} from 'components'
import {
  useEngagement
} from 'hooks'
import {
  Download
} from 'icons'
import {
  downloadAttachment,
  formatDateTime
} from 'utils-em'

import ActivityItem from '../ActivityItem'

const Message = ({ activity, engagementId, ...props }) => {
  const {
    htmlBody,
    textBody,
    attachments,
    sender,
    senderName,
    senderAddress
  } = activity
  const {
    state,
    relayAddress
  } = useEngagement(engagementId)

  const [messageExpanded, setMessageExpanded] = React.useState(false)

  const displaySenderName = sender?.firstName || senderName || senderAddress
  const subText = (htmlBody && <SanitizedHTML html={htmlBody} />) || textBody

  return (
    <ActivityItem
      title={`Message from ${displaySenderName}`}
      subtitle={`${formatDateTime(activity.dateCreated)} via email relay`}
      {...props}
    >
      {subText && (
        <Typography variant="body1" sx={{ height: messageExpanded ? 'auto' : '24px', overflowY: 'clip' }}>
          {subText}
        </Typography>
      )}
      <Link
        variant="body"
        onClick={() => setMessageExpanded(!messageExpanded)}
      >
        {messageExpanded ? 'Collapse' : 'Show full message'}
      </Link>
      {attachments && attachments.length ? (
        <Box sx={{ mt: 2 }}>
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {attachments.map((attachment) => (
              <Chip
                key={attachment.id}
                label={<Typography variant="bodyBold">{attachment.filename}</Typography>}
                color="primary"
                variant="outlined"
                sx={{ borderWidth: '2px' }}
                onClick={(e) => downloadAttachment(e, attachment.id, attachment.filename)}
                // Delete icon and onDelete are workarounds to display an icon
                // on the right hand side of the Chip. Trying to put this in
                // the `label` caused lots of layout issues
                deleteIcon={<Download style={{ color: 'inherit' }} />}
                onDelete={() => { }}
              />
            ))}
          </Stack>
        </Box>
      ) : null}
      {subText && state !== 'closed' && (
        <Box sx={{ mt: 2 }}>
          <Button
            component={Link}
            variant="text"
            href={`mailto:${relayAddress}`}
            sx={{ ml: 0 }}
          >
            Reply
          </Button>
        </Box>
      )}
    </ActivityItem>
  )
}

Message.propTypes = {
  activity: PropTypes.object.isRequired,
  engagementId: PropTypes.number.isRequired
}

export default Message
