import { connect } from 'react-redux'
import { push } from 'redux-first-history'

import { buildJsonApiOrdered } from 'utils-em'
import { Dialogs, JsonAPI } from 'store'

import { getCountJsonApi } from '../../utils-em/buildJsonApi'

import UserTable from './UserTable'

const mapStateToProps = ({ data }) => ({
  customerUsers: buildJsonApiOrdered(data, 'customerUsers'),
  customerUsersCount: getCountJsonApi(data, 'customerUsers') || 0,
  adminUsers: buildJsonApiOrdered(data, 'adminUsers'),
  adminUsersCount: getCountJsonApi(data, 'adminUsers') || 0,
  advisorUsers: buildJsonApiOrdered(data, 'advisors'),
  advisorUsersCount: getCountJsonApi(data, 'advisors') || 0
})

const mapDispatchToProps = {
  openDialog: (dialog) => (dispatch) => dispatch(Dialogs.openDialog(dialog)),
  getCustomerUsers: (size, page, sort, filter) => (
    JsonAPI.getAll({
      type: 'customerUsers',
      queryStringParams: {
        'page[number]': page,
        'page[size]': size,
        include: 'divisions,customer',
        filter,
        sort
      }
    })
  ),
  getAdminUsers: (size, page, sort, filter) => (
    JsonAPI.getAll({
      type: 'adminUsers',
      queryStringParams: {
        'page[number]': page,
        'page[size]': size,
        filter,
        sort
      }
    })
  ),
  getAdvisorUsers: (size, page, sort, filter) => (
    JsonAPI.getAll({
      type: 'advisors',
      queryStringParams: {
        'page[number]': page,
        'page[size]': size,
        include: 'recruiter,companies',
        filter,
        sort
      }
    })
  ),
  navigateTo: (path) => (dispatch) => dispatch(push(path))
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTable)
