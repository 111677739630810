import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Skeleton,
  useTheme
} from '@mui/material'
import { Heart } from 'icons'
import {
  SaveAccountListOrgWrapper
} from 'components'

const BookmarkAccountButton = ({ orgId, accountSaved, savedListLoaded, checkIfAccountSaved }) => {
  const theme = useTheme()

  if (!savedListLoaded) return <Skeleton />

  return (
    <SaveAccountListOrgWrapper
      orgId={orgId}
      isCurrentlySaved={accountSaved}
      enableUnsave
      onUpdate={checkIfAccountSaved}
    >
      <Button
        variant="text"
        startIcon={<Heart sx={{ fill: accountSaved ? `${theme.palette.primary.main} !important` : 'none' }} />}
      >
        {accountSaved ? 'Account saved' : 'Save account'}
      </Button>
    </SaveAccountListOrgWrapper>
  )
}

BookmarkAccountButton.propTypes = {
  orgId: PropTypes.string.isRequired,
  accountSaved: PropTypes.string.isRequired,
  savedListLoaded: PropTypes.bool.isRequired,
  checkIfAccountSaved: PropTypes.func.isRequired
}

export default BookmarkAccountButton
