import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Typography
} from '@mui/material'
import {
  useEngagement
} from 'hooks'
import {
  Pencil
} from 'icons'
import {
  TimeslotProposalRanges
} from 'components'
import {
  TIMEZONE_MAP
} from 'utils-em'

const CallAvailability = ({ engagementId }) => {
  const { proposedTimeslotProposal } = useEngagement(engagementId)
  const timezone = TIMEZONE_MAP?.[proposedTimeslotProposal.tzName] || null
  return (
    <>
      <Typography variant="h3">
        Your availability:
      </Typography>
      <TimeslotProposalRanges proposalId={proposedTimeslotProposal.id} />
      {timezone && (
        <Typography variant="tiny">
          {`All times in ${timezone}`}
        </Typography>
      )}
      <Box>
        <Button
          variant="text"
          startIcon={<Pencil />}
          onClick={console.log('TODO')}
          sx={{ ml: 0 }}
        >
          Edit availability
        </Button>
      </Box>
    </>
  )
}

CallAvailability.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default CallAvailability
