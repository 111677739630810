import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, IconButton } from '@mui/material'
import { openDialog } from 'utils-em'
import { AddTopic } from 'icons'
import QuestionsTopicsDialog from './QuestionsTopicsDialog'

const QuestionsTableActions = ({ question, reload }) => (
  <Tooltip title="Edit Topics">
    <IconButton
      color="primary"
      onClick={() => openDialog((
        <QuestionsTopicsDialog reload={reload} question={question} />
      ))}
    >
      <AddTopic />
    </IconButton>
  </Tooltip>
)

QuestionsTableActions.propTypes = {
  question: PropTypes.object.isRequired,
  reload: PropTypes.object.isRequired,
}

export default QuestionsTableActions
