import React from 'react'
import PropTypes from 'prop-types'
import { JsonApiDataGrid } from 'components'
import QuestionsTableActions from './QuestionsTableActions'

const SurveyQuestionsTable = ({ surveyId, typeformFormId }) => {
  const [reload, setReload] = React.useState(0)
  const columns = [
    {
      fieldName: 'typeformId',
      field: 'typeformFieldId',
      type: 'string',
      headerName: 'Typeform Field ID',
      width: 150,
    },
    {
      fieldName: 'questionText',
      field: 'questionText',
      type: 'string',
      headerName: 'Question Text',
      flex: 1,
    },
    {
      fieldName: 'questionType',
      field: 'questionType',
      type: 'string',
      headerName: 'Question Type',
      width: 300,
    },
    {
      fieldName: 'topicNames',
      field: 'topicNames',
      headerName: 'Topics',
      width: 300,
    },
    {
      field: 'actions',
      headerName: '',
      width: 200,
      disableExport: true,
      disableSort: true,
      disableFilter: true,
      valueGetter: ({ row }) => row.id,
      renderCell: ({ row }) => <QuestionsTableActions question={row} reload={{ value: reload, setReload }} />
    },
  ]

  const renderedColumns = columns.map((column) => (
    {
      ...column,
      cellClassName: column.actions ? undefined : (params) => (params.row.archivedDate !== null ? 'discarded' : '')
    }))

  return (
    <JsonApiDataGrid
      disableExport
      endpoint="questions"
      type="questions"
      columns={renderedColumns}
      density="compact"
      jsonApiOptions={{
        include: ['topics'],
        filters: [
          {
            name: 'surveys',
            op: 'any',
            val: {
              name: 'id',
              op: 'eq',
              val: surveyId,
            },
          },
        ],
      }}
      title="Survey Questions"
      mode="server"
      dependencies={[typeformFormId, reload]}
    />
  )
}

SurveyQuestionsTable.defaultProps = {
  typeformFormId: null,
}

SurveyQuestionsTable.propTypes = {
  surveyId: PropTypes.string.isRequired,
  typeformFormId: PropTypes.string,
}

export default SurveyQuestionsTable
