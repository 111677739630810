import PropTypes from 'prop-types'
import React from 'react'
import { some } from 'lodash'

import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem
} from '@mui/material'

const EditAllotmentDialog = ({
  contract,
  contractSkuAllotment,
  skus,
  handleClose,
  saveAllotment,
  showSuccessMessage,
  showErrorMessage
}) => {
  const [allotment, setAllotment] = React.useState(contractSkuAllotment.isUnlimitedAllotment ? 0 : contractSkuAllotment.allotment)
  const [isUnlimitedAllotment, setIsUnlimitedAllotment] = React.useState(contractSkuAllotment.isUnlimitedAllotment)
  const [skuId, setSkuId] = React.useState(contractSkuAllotment.skuId || '')

  const handleSubmit = () => {
    const allotmentToSave = {
      skuId,
      allotment,
      isUnlimitedAllotment,
      contractId: contract.id
    }

    if (!contractSkuAllotment.id && some(contract.contractSkuAllotments, (csa) => csa.skuId === parseInt(skuId, 10))) {
      showErrorMessage({
        text: 'Allotment already exists for contract',
        timeout: 2000
      })
      return
    }

    if (contractSkuAllotment.id) {
      allotmentToSave.id = contractSkuAllotment.id
    }
    saveAllotment(allotmentToSave).then((res) => {
      if (!res.ok) {
        showErrorMessage({
          text: `Save Contract failed: ${res.error.errors.map((e) => e.detail).join('; ')}`,
          timeout: 2000
        })
      } else {
        showSuccessMessage({
          text: 'Contract Saved',
          timeout: 2000
        })
        handleClose()
      }
    })
  }

  return (
    <Dialog
      open
      maxWidth="lg"
      scroll="paper"
      fullWidth
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>{`${contractSkuAllotment.id ? 'Edit' : 'Create'} Allotment`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectValidator
                label="Sku"
                validators={['required']}
                errorMessages={['Required Field']}
                value={skuId}
                onChange={(event) => { setSkuId(event.target.value) }}
                fullWidth
              >
                { skus.map((sku) => (
                  <MenuItem key={sku.id} value={sku.id}>{sku.name}</MenuItem>
                ))}
              </SelectValidator>
            </Grid>
            <Grid item xs={3} sx={{ mt: 3 }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={isUnlimitedAllotment}
                    onChange={() => {
                      if (!isUnlimitedAllotment) setAllotment(0)
                      setIsUnlimitedAllotment(!isUnlimitedAllotment)
                    }}
                  />
                )}
                label="Unlimited calls"
              />
            </Grid>
            <Grid item xs={9}>
              <TextValidator
                fullWidth
                label="Allotment"
                disabled={isUnlimitedAllotment}
                value={allotment}
                onChange={(event) => { setAllotment(event.target.value) }}
                margin="normal"
                validators={['required', 'isNumber']}
                errorMessages={['Required Field', 'Must be a number']}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  )
}

EditAllotmentDialog.defaultProps = {
  contractSkuAllotment: null
}

EditAllotmentDialog.propTypes = {
  contract: PropTypes.object.isRequired,
  contractSkuAllotment: PropTypes.object,
  skus: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  saveAllotment: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired
}

export default EditAllotmentDialog
